<template>
  <vs-row vs-type="flex" class="img-uploader-wrapper">
    <vs-col
      vs-type="flex"
      vs-justify="center"
      vs-align="center"
      vs-w="3"
      v-for="(image, index) in imagesArray"
      :key="index"
      class="grid"
    >
      <div class="wrapper">
        <img :src="image" class="img-uploader" alt="Image" v-if="accept == 'image/*'"/>
        <vs-radio color="primary" v-model="coverImage" :vs-value="index" v-if="accept == 'image/*' && cover">Cover
          Image
        </vs-radio>
        <video height="100%" id="video" class="img-uploader"
               v-if="accept == 'video/*' && !image.includes('youtube.com')" autoplay muted>
          <source :src="image">
        </video>
        <iframe class="img-uploader" :src="image + '?mute=1&autoplay=1'"
                v-if="accept == 'video/*' && image.includes('youtube.com')"/>
        <!--        <span class="dimensions" :id="index">{{getDimensions(image,index)}}</span>-->
        <div class="overlay">
          <x-icon
            size="1.5x"
            class="custom-class delete"
            @click="confirm_delete_image(index)"
          />
        </div>
      </div>
    </vs-col>
    <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="3" class="grid z">
      <div class="centerx display-img">
        <vs-upload
          multiple
          automatic
          single-upload
          :headers="uploadImageHeaders"
          :text="title"
          :action="uploadBaseUrl + '/config/upload-media'"
          fileName="image"
          :data="imageData"
          :accept="accept"
          :show-upload-button="false"
          @on-success="successUpload"
          @on-error="failUpload"
        />
      </div>
    </vs-col>
  </vs-row>
</template>

<script>
  import { XIcon } from "vue-feather-icons";

  export default {
    name: "img-upload",
    components: {
      XIcon
    },
    props: {
      coverIndex: {
        required: false,
        default: 0
      },
      cover: {
        required: false,
        default: false
      },
      images: {
        required: true,
        default: []
      },
      title: {
        required: true,
        default: ""
      },
      parent: {
        required: false,
        default: "parent"
      },
      single: {
        required: false,
        default: false
      },
      accept: {
        required: false,
        default: "image/*"
      }
    },
    data() {
      return {
        coverImage: 0,
        uploadBaseUrl: process.env.VUE_APP_ADMIN_URL,
        baseImagesURL: "http://mansour-dev.s3.us-east-2.amazonaws.com/",
        imagesArray: [],
        imageData: {
          category: "config"
        },
        uploadImageHeaders: {
          "Access-Control-Allow-Origin": "* | http://192.241.234.75:9109"
        }
      };
    },
    watch: {
      coverImage: {
        immediate: true,
        handler(value) {
          this.$emit("getCover", value);
        }
      }
    },
    methods: {
      getDimensions(url, id) {
        let image = new Image();
        image.src = url;

        image.onload = () => {
          //console.log({status: `${image.width} X ${image.height}`});
          document.getElementById(
            id
          ).innerText = `${image.width}px X ${image.height}px`;
          // return `${image.width}px X ${image.height}px`;
        };
      },
      successUpload(e) {
        //console.log({imageUploadEvent: e});
        let urlResponse = JSON.parse(e.target.response).data;
        let imageURL = this.baseImagesURL + urlResponse;

        if (
          this.parent === "edit-parts" ||
          this.parent === "edit-sign" ||
          this.single
        ) {
          this.imagesArray = [];
        }

        this.imagesArray.push(imageURL);

        this.$emit("image_uploaded", this.imagesArray);
        this.$emit("single_upload", urlResponse);
        this.$vs.notify({
          title: "Success",
          text: "Image is uploaded successfully!",
          color: "primary"
        });
      },
      failUpload() {
        this.$vs.notify({
          title: "Error",
          text: "Failed to Upload Image!",
          color: "danger"
        });
      },
      confirm_delete_image(index) {
        this.$vs.dialog({
          type: "confirm",
          accept: () => this.delete_image(index),
          color: "danger",
          title: "Delete Image",
          text: "You are going to delete this image"
        });
      },
      delete_image(index) {
        this.imagesArray.splice(index, 1);
      }
    },
    mounted() {
      // if (this.cover && this.parent === 'edit-news') {
      //   for (let i in this.images) {
      //     this.imagesArray.push(this.images[i].image);
      //     if(this.images[i].cover == 1){
      //       this.coverImage = i;
      //     }
      //   }
      // }else {
        this.imagesArray = this.images;
        this.coverImage = this.coverIndex;
      // }
    }
  };
</script>

<style lang="scss">
  .img-uploader {
    border-radius: 5px;
    height: 100%;
    width: 100%;
  }

  .display-img {
    div {
      .img-upload {
        display: none !important;
      }
    }
  }

  .wrapper {
    position: relative;
    height: 198px;
    width: 198px;
    transition: all 0.2s;
    margin: 15px !important;
    text-align: center;

    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      transition: all 0.2s;
      border-radius: 5px;
      opacity: 0;
      background-color: rgba(0, 0, 0, 0.5);
    }

    &:hover {
      .overlay {
        opacity: 1;
      }
    }
  }

  .delete {
    position: absolute;
    top: 20px;
    right: 20px;
    border-radius: 30px;
    transition: all 0.2s;
    cursor: pointer;

    &:hover {
      background-color: whitesmoke;
      height: 22px;
      width: 22px;
    }
  }

  .img-uploader-wrapper {
    margin: 0;
    padding: 0;
    position: relative;
  }

  .grid {
    width: 20% !important;
    z-index: 1;
    margin-left: 10px !important;
  }

  .z {
    z-index: 0;
  }
</style>
