<template>
    <div>
        <edit-about-us v-if="isEditing" :about="about" @cancel="reset"/>
    </div>
</template>

<script>
    import EditAboutUs from './edit-about-us';

    export default {
        name: 'about-us',
        components: { EditAboutUs },
        data() {
            return {
                isEditing: false,
                about: {
                    about_mansour_title: { ar: '', en: '' },
                    about_mansour_sub_title: { ar: '', en: '' },
                    about_mansour_text: { ar: '', en: '' },
                    abount_mansour_images: []
                }
            };
        },
        methods: {
            reset(){
              this.isEditing = false;
              this.getAbout();
            },
            getAbout() {
                this.$vs.loading();
                this.$httpAbout.get('/config/about-mansour')
                    .then(r => {
                        this.about = r.data.data;
                        //console.log(this.about);
                        this.$vs.loading.close();
                        this.isEditing = true;
                    })
                    .catch(() => {
                       // console.log(e);
                        this.$vs.notify({
                            title: this.$t('common.error'),
                            text: this.$t('common.couldnt-load-data'),
                            color: 'danger'
                        });
                        this.$vs.loading.close();
                    });
            }
        },
        mounted() {
            this.getAbout();
        }
    };
</script>

<style scoped lang="scss">

</style>
