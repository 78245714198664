<template>
  <div>

    <breadcrumb name="About Us" title="About Us Information" subtitle="Edit"/>
    <div class="body">
      <h6 class="mandatory">Fields marked with (*) are mandatory</h6>
      <div>
        <div class="english">
          <vs-input label="Title English*" v-model="title_en"
                    size="medium"
                    class="input" :danger="flags.title_en" danger-text="This field is mandatory"/>
          <vs-input label="Subtitle English" v-model="subtitle_en"
                    size="medium" class="input"/>
          <small class="label">Description English*</small>
          <ckeditor :editor="editor" v-model="text_en" :config="editorConfigEn" class="input" v-if="!showHTML"/>
          <textarea v-model="text_en" v-if="showHTML"></textarea>
          <small v-if="flags.text_en" style="color: red">This field is mandatory</small>
          <small v-if="flags.lengthExceeded_en" style="color: red">Text should't be more than 3000 characters</small>
        </div>
        <div class="arabic">
          <vs-input label="Title Arabic*" v-model="title_ar"
                    size="medium"
                    class="input" :danger="flags.title_ar" danger-text="This field is mandatory"/>
          <vs-input label="Subtitle Arabic" v-model="subtitle_ar"
                    size="medium" class="input"/>
          <small class="label">Description Arabic*</small>
          <ckeditor :editor="editor" v-model="text_ar" :config="editorConfigAr" class="input" v-if="!showHTML"/>
          <textarea v-model="text_ar" v-if="showHTML"></textarea>
          <small v-if="flags.text_ar" style="color: red">This field is mandatory</small>
          <small v-if="flags.lengthExceeded_ar" style="color: red">Text should't be more than 3000 characters</small>
        </div>
      </div>
      <div>
        <div class="clr"/>
      </div>
      <h6 v-if="noImage" class="error">There must be at least one image!</h6>
      <img-upload :images="about.abount_mansour_images" @image_uploaded="saveImage" v-if="imageUpload"
                  title="Upload Images" :key="imagesCounter"/>
      <div class="optionBtns">
        <vs-button :disabled="invalidForm" class="mr-5 save" icon-pack="feather" icon="icon-save"
                   @click="confirmSaveDate">Save
        </vs-button>
        <vs-button class="ml-5 cancel" type="border" color="primary" @click="$emit('cancel')">
          Reset
        </vs-button>
        <vs-button class="ml-5" type="border" @click="showHTML = !showHTML">{{showHTML ? "Hide HTML" : "Show HTML"}}</vs-button>
      </div>
    </div>
  </div>
</template>

<script>
  import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
  import ImgUpload from "../../../components/general/img-upload";
  import { ChevronsRightIcon, HomeIcon } from "vue-feather-icons";
  import Breadcrumb from "../../../components/general/breadcrumb";

  export default {
    name: "edit-about-us",
    components: { Breadcrumb, ImgUpload, HomeIcon, ChevronsRightIcon },
    props: {
      about: {
        required: true,
        default: {
          about_mansour_title: { ar: "", en: "" },
          about_mansour_sub_title: { ar: "", en: "" },
          about_mansour_text: { ar: "", en: "" },
          abount_mansour_images: []
        }
      }
    },
    data() {
      return {
        showHTML: false,
        imageUpload: true,
        baseImagesURL: "http://mansour-dev.s3.us-east-2.amazonaws.com/",
        title_ar: this.about.about_mansour_title.ar,
        subtitle_ar: this.about.about_mansour_sub_title.ar,
        text_ar: this.about.about_mansour_text.ar,
        title_en: this.about.about_mansour_title.en,
        subtitle_en: this.about.about_mansour_sub_title.en,
        text_en: this.about.about_mansour_text.en,
        editor: ClassicEditor,
        imagesCounter: 0,
        editorConfigAr: {
          placeholder: "تفاصيل",
          language: "ar"
        },
        editorConfigEn: {
          placeholder: "Details",
          language: "en"
        },
        imageData: {
          category: "config"
        },
        uploadImageHeaders: {
          "Access-Control-Allow-Origin": "* | http://192.241.234.75:9109"
        },
        flags: {
          title_ar: false,
          title_en: false,
          text_ar: false,
          text_en: false,
          lengthExceeded_ar: false,
          lengthExceeded_en: false
        }
      };
    },
    methods: {
      saveImage(imagesArray) {
        this.about.abount_mansour_images = imagesArray;
        //console.log(this.about.abount_mansour_images);
        this.imagesCounter++;
      },
      confirmSaveDate() {
        this.$vs.dialog({
          title: "Confirm",
          text: "You are going to save these data!",
          color: "primary",
          accept: () => this.saveData(),
          type: "confirm"
        });
      },
      saveData() {
        this.$vs.loading();

        let images = [];

        for (let i = 0; i < this.about.abount_mansour_images.length; i++) {
          let imageURL = this.about.abount_mansour_images[i].split(this.baseImagesURL).pop();
          images.push(imageURL);
        }

        let payload = {
          about_mansour_title: {
            ar: this.title_ar,
            en: this.title_en
          },
          about_mansour_sub_title: {
            ar: this.subtitle_ar,
            en: this.subtitle_en
          },
          about_mansour_text: {
            ar: this.text_ar,
            en: this.text_en
          },
          images: images
        };

        //console.log(payload);

        this.$httpAbout.post("/config/about-mansour", payload)
          .then(() => {
            this.$vs.loading.close();
            //console.log(r);
            this.$vs.notify({
              title: "Saved",
              text: "Data is Saved Successfully",
              color: "primary"
            });
          })
          .catch(() => {
            this.$vs.loading.close();
            //console.log(e);
            this.$vs.notify({
              title: "Error",
              text: "Error is occurred!\nCouldn't Save Data!",
              color: "danger"
            });
          });
      },
      successUpload(e) {
        //console.log(e);
        let imageURL = this.baseImagesURL + JSON.parse(e.target.response).data;
        this.about.abount_mansour_images.push(imageURL);
        //console.log(this.about.abount_mansour_images);
      },
      failUpload() {
        this.$vs.notify({
          title: "Error",
          text: "Failed to Upload Image!",
          color: "danger"
        });
      },
      confirm_delete_image(index) {
        this.$vs.dialog({
          type: "confirm",
          accept: () => this.delete_image(index),
          color: "danger",
          title: "Delete Image",
          text: "You are going to delete this image"
        });
      },
      delete_image(index) {
        this.about.abount_mansour_images.splice(index, 1);
      }
    },
    computed: {
      invalidForm() {
        if (this.flags.title_ar || this.flags.title_en || this.flags.text_ar || this.flags.text_en || this.flags.lengthExceeded_ar || this.flags.lengthExceeded_en || this.noImage) {
          return true;
        } else {
          return false;
        }
      },
      noImage() {
        return this.about.abount_mansour_images.length === 0;
      }
    },
    watch: {
      title_ar(v) {
        v === "" ? this.flags.title_ar = true : this.flags.title_ar = false;
      },
      title_en(v) {
        v === "" ? this.flags.title_en = true : this.flags.title_en = false;
      },
      text_ar(v) {
        v.replace(/(<([^>]+)>)/ig, "") === "" ? this.flags.text_ar = true : this.flags.text_ar = false;
        v.replace(/(<([^>]+)>)/ig, "").length > 3000 ? this.flags.lengthExceeded_ar = true : this.flags.lenghtExceeded_ar = false;
      },
      text_en(v) {
        v.replace(/(<([^>]+)>)/ig, "") === "" ? this.flags.text_en = true : this.flags.text_en = false;
        v.replace(/(<([^>]+)>)/ig, "").length > 3000 ? this.flags.lengthExceeded_en = true : this.flags.lengthExceeded_en = false;
      }
    }
  };
</script>

<style scoped lang="scss">
  @import "../../basic-styles/mixins";

  %flex {
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }

  .img-fluid {
    border: 1px solid #00000030;
    height: auto;
    width: 200px;
    max-width: 100%;
    border-radius: 20px;
    @include margin-x(20px);
  }

  .images {
    @include margin-y(30px);
    text-align: center;
  }

  .optionBtns {
    @include margin-x(20px);
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .save {
      position: relative;
      left: 25px;
    }
  }

  .input {
    width: 100%;
    @include margin-y(30px)
  }

  textarea {
    height: 350px;
    width: 100%;
  }

  .arabic, .english {
    @extend %flex;
    flex-flow: column;
    width: 100%;
    padding: 20px;
  }

  h1 {
    margin-bottom: 25px;
  }

  .inputs {
    @extend %flex;
  }

  ul {
    li {
      display: inline;
    }
  }

  .clr {
    clear: both;
  }

  $primaryColor: #054f7c;

  .breadcrumb {
    margin: 20px;
  }

  .back-to {
    cursor: pointer;
    color: $primaryColor;
    font-weight: bold;
  }

  .main-component {
    color: $primaryColor;
  }

  .required {
    color: red;
    font-size: 1.5rem;
  }

  .mandatory {
    color: red;
  }

  .arabic, .english {
    width: 50%;
    display: inline-block;
    vertical-align: top;
  }

  .error {
    color: red;
    text-align: center;
    margin-bottom: 20px;
  }

  .body {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
  }

  .img-uploader {
    height: 200px;
    max-width: 100%;
    width: auto;
    border-radius: 20px;
    padding: 10px
  }

  .custom-class {
    color: $primaryColor;
  }

  .title {
    font-size: 2rem;
    font-weight: 400;
  }

  .separator {
    font-size: 2rem;
    font-weight: 100;
    color: #ccc;
  }

  .label {
    color: #000;
  }

  .centered {
    text-align: center;
  }

</style>
